"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineArticleViewFrame = exports.ArticleViewFrame = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/webapp-common/lib-es2015");
const main_article_view_1 = require("./main-article-view");
const sub_article_view_1 = require("./sub-article-view");
const constants_1 = require("./constants");
class ArticleViewFrame extends HTMLElement {
    constructor() {
        super();
        this.fetchItem = () => __awaiter(this, void 0, void 0, function* () {
            console.log(`fetchItem() called!`);
            const mainArticleWithSubArticles = yield this.apis.getMainArticleWithSubArticlesByPK({
                pk: this.dataPK,
            });
            if (mainArticleWithSubArticles === null) {
                console.error(`fetchItem(): documentEntity is null`);
                return;
            }
            this.mainArticleView.putTitleAndContent(mainArticleWithSubArticles.pk, mainArticleWithSubArticles.title, mainArticleWithSubArticles.content);
            mainArticleWithSubArticles.children.forEach(subArticle => {
                const subArticleView = new sub_article_view_1.SubArticleView();
                subArticleView.setAttribute(constants_1.ATTR_IS_LOGIN, this.isLogin ? 'true' : 'false');
                subArticleView.setAttribute(constants_1.ATTR_ROUTER_PATH_GROUP, this.routerPathGroup);
                subArticleView.setAttribute(constants_1.ATTR_ROUTER_SUB_PATH_GROUP, this.routerSubPathGroup);
                subArticleView.setAttribute(constants_1.ATTR_ARTICLE_SUB_TYPE_NAME, this.articleSubTypeName);
                this.subArticleViewContainer.appendChild(subArticleView);
                subArticleView.putContent(subArticle.parent_pk, subArticle.pk, subArticle.content);
                this.subArticleViewContainer.appendChild(document.createElement('HR'));
            });
            console.log(`fetchItem(): done`);
        });
        this.style.setProperty('display', 'block');
        this.classList.add('document-view-frame');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataPK = parseInt((0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_DATA_PK));
            if (Number.isNaN(this.dataPK)) {
                throw Error('data-pk is not a number');
            }
            this.isLogin = (0, lib_es2015_3.isOauthLogin)((0, lib_es2015_3.getServerProps)());
            this.router = (0, lib_es2015_1.useRouter)();
            this.routerPathGroup = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_PATH_GROUP);
            this.routerSubPathGroup = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_SUB_PATH_GROUP);
            this.articleMainTypeName = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ARTICLE_MAIN_TYPE_NAME);
            this.articleSubTypeName = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ARTICLE_SUB_TYPE_NAME);
            this.innerHTML = `
    <main-article-view 
      ${constants_1.ATTR_DATA_PK}="${this.dataPK}" 
      ${constants_1.ATTR_IS_LOGIN}="${this.isLogin ? 'true' : 'false'}" 
      ${constants_1.ATTR_ROUTER_PATH_GROUP}="${this.routerPathGroup}"
      ${constants_1.ATTR_ARTICLE_MAIN_TYPE_NAME}="${this.articleMainTypeName}"
      >    
    </main-article-view>
    <hr >
    <div class="sub-article-view-container"></div>
    <div class="footer">
      <button class="create-sub-article">+${this.articleSubTypeName}</button>
    </div>
    `;
            this.mainArticleView = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'main-article-view');
            this.subArticleViewContainer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.sub-article-view-container');
            const createSubArticleBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.create-sub-article');
            if (!this.isLogin) {
                createSubArticleBtn.style.setProperty('display', 'none');
            }
            else {
                createSubArticleBtn.addEventListener('click', () => {
                    this.router.push(`/web/${this.routerPathGroup}/${this.routerSubPathGroup}/edit/0?${constants_1.ATTR_MAIN_ARTICLE_PK}=${this.dataPK}`);
                });
            }
            yield this.fetchItem();
        });
    }
}
exports.ArticleViewFrame = ArticleViewFrame;
const defineArticleViewFrame = (name) => {
    (0, main_article_view_1.defineMainArticleView)('main-article-view');
    (0, sub_article_view_1.defineSubArticleView)('sub-article-view');
    (0, lib_es2015_1.defineIfUndefined)(name, ArticleViewFrame);
};
exports.defineArticleViewFrame = defineArticleViewFrame;
