"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.problemRouteConfig = exports.theoremRouteConfig = exports.definitionRouteConfig = exports.documentRouteConfig = void 0;
exports.documentRouteConfig = {
    routerPathGroup: 'document',
    articleMainTypeName: 'document',
    routerSubPathGroup: 'comment',
    articleSubTypeName: 'comment',
    isListShowContent: false,
};
exports.definitionRouteConfig = {
    routerPathGroup: 'definition',
    articleMainTypeName: 'definition',
    routerSubPathGroup: 'statement',
    articleSubTypeName: 'statement',
    isListShowContent: false,
};
exports.theoremRouteConfig = {
    routerPathGroup: 'theorem',
    articleMainTypeName: 'theorem',
    routerSubPathGroup: 'proof',
    articleSubTypeName: 'proof',
    isListShowContent: true,
};
exports.problemRouteConfig = {
    routerPathGroup: 'problem',
    articleMainTypeName: 'problem',
    routerSubPathGroup: 'solution',
    articleSubTypeName: 'solution',
    isListShowContent: true,
};
