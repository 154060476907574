"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildPresentableContentOrEmptyPresentableContent = exports.buildPresentableTitleOrEmptyPresentableTitle = exports.buildEmptyPresentableContent = exports.buildEmptyPresentableTitle = exports.buildEditableContentOrInitialEditableContent = exports.buildEditableTitleOrInitialEditableTitle = exports.buildInitialEditableContent = exports.buildInitialEditableTitle = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const buildInitialEditableTitle = (documentEditor) => {
    const initTitle = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div class="title-container" data-contenteditable="true">
      <span style="color: #dddddd;">Title...</span>
    </div>`);
    documentEditor.convertRecordableToEditable(initTitle);
    const removeTitleText = () => {
        initTitle.innerHTML = '';
        initTitle.removeEventListener('focus', removeTitleText);
    };
    initTitle.addEventListener('focus', removeTitleText);
    return initTitle;
};
exports.buildInitialEditableTitle = buildInitialEditableTitle;
const buildInitialEditableContent = (documentEditor) => {
    const initContent = (0, lib_es2015_1.buildNodeByOuterHTML)(
    // line-height can be modified by document-editor
    `<div class="hodol-document-editor-content recordable" data-contenteditable="true" style="line-height: 180%;">
      <span style="color: #dddddd; font-size: 1.5rem;">Content...</span>
    </div>`);
    documentEditor.convertRecordableToEditable(initContent);
    const removeContentText = () => {
        initContent.innerHTML = '';
        initContent.removeEventListener('focus', removeContentText);
    };
    initContent.addEventListener('focus', removeContentText);
    return initContent;
};
exports.buildInitialEditableContent = buildInitialEditableContent;
const buildEditableTitleOrInitialEditableTitle = (titleSourceHTML, documentEditor) => {
    if (titleSourceHTML === '')
        return (0, exports.buildInitialEditableTitle)(documentEditor);
    const editable = (0, lib_es2015_1.buildNodeByOuterHTML)(titleSourceHTML);
    documentEditor.convertRecordableToEditable(editable);
    return editable;
};
exports.buildEditableTitleOrInitialEditableTitle = buildEditableTitleOrInitialEditableTitle;
const buildEditableContentOrInitialEditableContent = (contentSourceHTML, documentEditor) => {
    if (contentSourceHTML === '')
        return (0, exports.buildInitialEditableContent)(documentEditor);
    const editable = (0, lib_es2015_1.buildNodeByOuterHTML)(contentSourceHTML);
    documentEditor.convertRecordableToEditable(editable);
    return editable;
};
exports.buildEditableContentOrInitialEditableContent = buildEditableContentOrInitialEditableContent;
const buildEmptyPresentableTitle = (documentViewer) => {
    return documentViewer.convertRecordableSourceToPresentable(`<div class="title-container" data-contenteditable="true"></div>`);
};
exports.buildEmptyPresentableTitle = buildEmptyPresentableTitle;
const buildEmptyPresentableContent = (documentViewer) => {
    return documentViewer.convertRecordableSourceToPresentable(`<div class="hodol-document-editor-content recordable" data-contenteditable="true">`);
};
exports.buildEmptyPresentableContent = buildEmptyPresentableContent;
const buildPresentableTitleOrEmptyPresentableTitle = (titleSourceHTML, documentViewer) => {
    if (titleSourceHTML === '')
        return (0, exports.buildEmptyPresentableTitle)(documentViewer);
    return documentViewer.convertRecordableSourceToPresentable(titleSourceHTML);
};
exports.buildPresentableTitleOrEmptyPresentableTitle = buildPresentableTitleOrEmptyPresentableTitle;
const buildPresentableContentOrEmptyPresentableContent = (contentSourceHTML, documentViewer) => {
    if (contentSourceHTML === '')
        return (0, exports.buildEmptyPresentableContent)(documentViewer);
    return documentViewer.convertRecordableSourceToPresentable(contentSourceHTML);
};
exports.buildPresentableContentOrEmptyPresentableContent = buildPresentableContentOrEmptyPresentableContent;
