"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineSubArticleEdit = exports.SubArticleEdit = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/webapp-common/lib-es2015");
const lib_es2015_3 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_4 = require("@hodol/document-editor/lib-es2015");
const main_article_view_1 = require("./main-article-view");
const constants_1 = require("./constants");
const common_1 = require("./common");
class SubArticleEdit extends HTMLElement {
    constructor() {
        super();
        this.fetchMainArticle = () => __awaiter(this, void 0, void 0, function* () {
            console.log(`fetchMainArticle() called!`);
            const mainArticleEntity = yield this.apis.getMainArticleByPK({
                pk: parseInt(this.mainArticlePK),
            });
            if (mainArticleEntity === null) {
                console.error(`fetchItem(): mainArticleEntity is null`);
                return;
            }
            this.mainArticleView.putTitleAndContent(mainArticleEntity.pk, mainArticleEntity.title, mainArticleEntity.content);
            console.log(`fetchMainArticle(): done`);
        });
        this.fetchSubArticle = () => __awaiter(this, void 0, void 0, function* () {
            console.log(`fetchSubArticle() called!`);
            const subArticleEntity = yield this.apis.getSubArticleByPK({
                pk: this.dataPK,
            });
            if (subArticleEntity === null) {
                console.error(`fetchSubArticle(): subArticleEntity is null`);
                return;
            }
            this.contentEditor.appendChild((0, common_1.buildEditableContentOrInitialEditableContent)(subArticleEntity.content, this.documentEditor));
            console.log(`fetchSubArticle(): done`);
        });
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.router = (0, lib_es2015_3.useRouter)();
            this.mainArticlePK = (0, lib_es2015_1.getAttributeOrThrowError)(this, constants_1.ATTR_MAIN_ARTICLE_PK);
            this.dataPK = parseInt((0, lib_es2015_1.getAttributeOrThrowError)(this, constants_1.ATTR_DATA_PK));
            if (Number.isNaN(this.dataPK)) {
                throw Error('data-pk is not a number');
            }
            this.isLogin = (0, lib_es2015_2.isOauthLogin)((0, lib_es2015_2.getServerProps)());
            this.isNew = this.dataPK === 0;
            this.routerPathGroup = (0, lib_es2015_1.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_PATH_GROUP);
            this.articleMainTypeName = (0, lib_es2015_1.getAttributeOrThrowError)(this, constants_1.ATTR_ARTICLE_MAIN_TYPE_NAME);
            this.articleSubTypeName = (0, lib_es2015_1.getAttributeOrThrowError)(this, constants_1.ATTR_ARTICLE_SUB_TYPE_NAME);
            this.innerHTML = `
    <div class="toolbar-container"></div>
    <div class="main-article-view-and-sub-article-editor">
      <div class="main-article-view">
        <main-article-view 
          ${constants_1.ATTR_DATA_PK}="${this.mainArticlePK}" 
          ${constants_1.ATTR_IS_LOGIN}="${this.isLogin ? 'true' : 'false'}" 
          ${constants_1.ATTR_ROUTER_PATH_GROUP}="${this.routerPathGroup}"
          ${constants_1.ATTR_ARTICLE_MAIN_TYPE_NAME}="${this.articleMainTypeName}"
          ${constants_1.ATTR_IS_HIDE_EDIT_BUTTON}="true"
          >
        </main-article-view>
      </div>
      <hr>
      <div class="sub-article-type-name-and-pk">${this.articleSubTypeName} ${this.dataPK}:</div>
      <div class="content-editor"></div>
    </div>

    <!-- The following will be moved into toolbar on mounted -->
    <div class="tool-box-group additional-tools" >
      <button class="save">Save</button>
      <button class="cancel">Cancel</button>
    </div>
    `;
            this.mainArticleView = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'main-article-view');
            this.contentEditor = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.content-editor');
            this.toolbarContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.toolbar-container');
            this.additionalTools = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.additional-tools');
            this.documentEditor = new lib_es2015_4.DocumentEditor(this.contentEditor, this.toolbarContainer, '/files/customize1/styles/fonts');
            this.documentEditor.appendToToolbar(this.additionalTools);
            const saveBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.save');
            saveBtn.addEventListener('click', () => {
                const recordableContent = (0, lib_es2015_1.getFirstElementChildOrThrowError)(this.contentEditor);
                this.documentEditor.convertEditableToRecordable(recordableContent);
                void (() => __awaiter(this, void 0, void 0, function* () {
                    if (this.isNew) {
                        const resp = yield this.apis.insertSubArticle({
                            content: this.contentEditor.innerHTML,
                            parent_pk: parseInt(this.mainArticlePK),
                        });
                        if (resp === null) {
                            alert('fail to post new document!');
                            return;
                        }
                        this.router.push(`/web/${this.routerPathGroup}/view/${this.mainArticlePK}`);
                    }
                    else {
                        const resp = yield this.apis.updateSubArticle(this.dataPK, {
                            content: this.contentEditor.innerHTML,
                        });
                        if (resp === null) {
                            alert('fail to update document!');
                            return;
                        }
                        this.router.push(`/web/${this.routerPathGroup}/view/${this.mainArticlePK}`);
                    }
                }))();
            });
            const cancelBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.cancel');
            cancelBtn.addEventListener('click', () => {
                const answer = confirm(`Your changes will be lost, continue?`);
                if (answer)
                    window.history.back();
            });
            yield this.fetchMainArticle();
            if (!this.isNew) {
                yield this.fetchSubArticle();
            }
            else {
                this.contentEditor.appendChild((0, common_1.buildInitialEditableContent)(this.documentEditor));
            }
        });
    }
}
exports.SubArticleEdit = SubArticleEdit;
const defineSubArticleEdit = (name) => {
    (0, main_article_view_1.defineMainArticleView)('main-article-view');
    (0, lib_es2015_3.defineIfUndefined)(name, SubArticleEdit);
};
exports.defineSubArticleEdit = defineSubArticleEdit;
