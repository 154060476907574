"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainSubArticleViews = exports.getMainSubArticleRoutes = void 0;
const main_article_list_1 = require("../main-sub-articles/main-article-list");
const article_view_frame_1 = require("../main-sub-articles/article-view-frame");
const main_article_edit_1 = require("../main-sub-articles/main-article-edit");
const sub_article_edit_1 = require("../main-sub-articles/sub-article-edit");
const apis_1 = require("./apis");
const constants_1 = require("../main-sub-articles/constants");
const getMainSubArticleRoutes = (cfg) => {
    const apis = new apis_1.Apis(cfg.articleMainTypeName);
    return [
        {
            path: `/web/${cfg.routerPathGroup}/list`,
            tag: 'main-article-list',
            pathParamNames: [],
            queryParamNames: [constants_1.ATTR_PAGE_NO, constants_1.ATTR_PAGE_SIZE, constants_1.ATTR_PAGE_SIZE],
            viewConstructor: main_article_list_1.MainArticleList,
            onConstructed: (view) => {
                const mainArticleList = view;
                mainArticleList.apis = apis;
            },
            attr: Object.fromEntries([
                [constants_1.ATTR_ROUTER_PATH_GROUP, cfg.routerPathGroup],
                [constants_1.ATTR_IS_LIST_SHOW_CONTENT, cfg.isListShowContent ? 'true' : 'false'],
            ]),
        },
        {
            path: `/web/${cfg.routerPathGroup}/view/:${constants_1.ATTR_DATA_PK}`,
            tag: 'article-view-frame',
            pathParamNames: [constants_1.ATTR_DATA_PK],
            queryParamNames: [],
            viewConstructor: article_view_frame_1.ArticleViewFrame,
            onConstructed: (view) => {
                const articleViewFrame = view;
                articleViewFrame.apis = apis;
            },
            attr: Object.fromEntries([
                [constants_1.ATTR_ROUTER_PATH_GROUP, cfg.routerPathGroup],
                [constants_1.ATTR_ROUTER_SUB_PATH_GROUP, cfg.routerSubPathGroup],
                [constants_1.ATTR_ARTICLE_MAIN_TYPE_NAME, cfg.articleMainTypeName],
                [constants_1.ATTR_ARTICLE_SUB_TYPE_NAME, cfg.articleSubTypeName],
            ]),
        },
        {
            path: `/web/${cfg.routerPathGroup}/edit/:${constants_1.ATTR_DATA_PK}`,
            tag: 'main-article-edit',
            pathParamNames: [constants_1.ATTR_DATA_PK],
            queryParamNames: [],
            viewConstructor: main_article_edit_1.MainArticleEdit,
            onConstructed: (view) => {
                const mainArticleEdit = view;
                mainArticleEdit.apis = apis;
            },
            attr: Object.fromEntries([[constants_1.ATTR_ROUTER_PATH_GROUP, cfg.routerPathGroup]]),
        },
        {
            path: `/web/${cfg.routerPathGroup}/${cfg.routerSubPathGroup}/edit/:${constants_1.ATTR_DATA_PK}`,
            tag: 'sub-article-edit',
            pathParamNames: [constants_1.ATTR_DATA_PK],
            queryParamNames: [constants_1.ATTR_MAIN_ARTICLE_PK],
            viewConstructor: sub_article_edit_1.SubArticleEdit,
            onConstructed: (view) => {
                const articleViewFrame = view;
                articleViewFrame.apis = apis;
            },
            attr: Object.fromEntries([
                [constants_1.ATTR_ROUTER_PATH_GROUP, cfg.routerPathGroup],
                [constants_1.ATTR_ARTICLE_MAIN_TYPE_NAME, cfg.articleMainTypeName],
                [constants_1.ATTR_ARTICLE_SUB_TYPE_NAME, cfg.articleSubTypeName],
            ]),
        },
    ];
};
exports.getMainSubArticleRoutes = getMainSubArticleRoutes;
const defineMainSubArticleViews = () => {
    (0, main_article_list_1.defineMainArticleList)('main-article-list');
    (0, article_view_frame_1.defineArticleViewFrame)('article-view-frame');
    (0, main_article_edit_1.defineMainArticleEdit)('main-article-edit');
    (0, sub_article_edit_1.defineSubArticleEdit)('sub-article-edit');
};
exports.defineMainSubArticleViews = defineMainSubArticleViews;
