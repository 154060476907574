"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.define = exports.MainFrame = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const main_frame_header_1 = require("./main-frame-header");
const main_frame_navigator_1 = require("./main-frame-navigator");
const routes_1 = require("../router/routes");
class MainFrame extends HTMLElement {
    constructor() {
        super();
        const routerView = (0, lib_es2015_2.buildNodeByOuterHTML)(`<div class="main-frame-router-view"></div>`);
        (0, lib_es2015_1.createRouter)(routes_1.routes, routerView);
        this.innerHTML = `
      <main-frame-header></main-frame-header>
      <div class="main-frame-body">
        <main-frame-navigator></main-frame-navigator>
        <div class="router-view placeholder"></div>
      </div>`;
        this.classList.add('main-frame');
        const mainFrameBody = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `div.main-frame-body`);
        mainFrameBody.replaceChild(routerView, (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `div.router-view.placeholder`));
        const header = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'main-frame-header');
        const navigator = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'main-frame-navigator');
        header.addEventListener('click-menu-btn', () => {
            navigator.style.setProperty('display', navigator.style.getPropertyValue('display') === 'none' ? 'block' : 'none');
        });
    }
    connectedCallback() { }
}
exports.MainFrame = MainFrame;
const define = (name) => {
    (0, main_frame_header_1.defineMainFrameHeader)('main-frame-header');
    (0, main_frame_navigator_1.defineMainFrameNavigator)('main-frame-navigator');
    (0, routes_1.defineViews)();
    (0, lib_es2015_1.defineIfUndefined)(name, MainFrame);
};
exports.define = define;
