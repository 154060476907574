"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const main_frame_1 = require("./main/main-frame");
(0, main_frame_1.define)('main-frame');
self.MonacoEnvironment = {
    getWorkerUrl: function (moduleId, label) {
        if (label === 'json') {
            return '/files/generated/scripts/monaco-workers/json.worker.js';
        }
        if (label === 'css' || label === 'scss' || label === 'less') {
            return '/files/generated/scripts/monaco-workers/css.worker.js';
        }
        if (label === 'html' || label === 'handlebars' || label === 'razor') {
            return '/files/generated/scripts/monaco-workers/html.worker.js';
        }
        if (label === 'typescript' || label === 'javascript') {
            console.log('return python worker address!!');
            return '/files/generated/scripts/monaco-workers/ts.worker.js';
        }
        if (label === 'python') {
            console.log('return python worker address!!');
            return '/files/generated/scripts/monaco-workers/python.worker.js';
        }
        return '/files/generated/scripts/monaco-workers/editor.worker.js';
    },
};
