"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainFrameHeader = exports.MainFrameHeader = void 0;
const lib_es2015_1 = require("@hodol/webapp-common/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/web-component-helper/lib-es2015");
class MainFrameHeader extends HTMLElement {
    constructor() {
        super();
        this.serverProps = (0, lib_es2015_1.getServerProps)();
        this.moveToLoginPage = () => {
            window.location.href = `${this.serverProps.memberWebAddr}/web/login?redirect_url=${window.location.href}`;
        };
        this.logout = () => __awaiter(this, void 0, void 0, function* () {
            const ans = confirm('Are you sure to logout?');
            if (ans) {
                window.location.href = `${this.serverProps.memberWebAddr}/web/logout?redirect_url=${window.location.href}`;
            }
        });
        this.moveToMemberEditPage = () => {
            window.location.href = `${this.serverProps.memberWebAddr}/web/edit?redirect_url=${window.location.href}`;
        };
        const isMobile = window.matchMedia(`(max-width: 600px)`).matches;
        this.innerHTML = `
      <div>
        <button class="header-btn menu-btn">Menu</button>
        <a href="/web"><button class="header-btn">Home</button></a>
      </div>
      <div class="member-control placeholder"></div>`;
        const menuBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.menu-btn');
        menuBtn.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('click-menu-btn', {
                bubbles: true,
                cancelable: true,
            }));
        });
        if ((0, lib_es2015_1.isOauthLogin)(this.serverProps)) {
            const member = this.serverProps.member;
            const memberControl = (0, lib_es2015_2.buildNodeByOuterHTML)(`
        <div class="member-control oauth-login">
          <button class="header-btn member-edit">${isMobile ? '' : member.pre_title}${member.nick}${isMobile ? '' : member.post_title}</button>
          <button class="header-btn logout">Logout</button>
        </div>
      `);
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.member-edit`).addEventListener('click', () => this.moveToMemberEditPage());
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.logout`).addEventListener('click', () => {
                void this.logout();
            });
            this.replaceChild(memberControl, (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `div.member-control`));
        }
        else if ((0, lib_es2015_1.isDirectLogin)(this.serverProps)) {
            const globalIdentifier = this.serverProps.globalIdentifier;
            const memberControl = (0, lib_es2015_2.buildNodeByOuterHTML)(`
        <div class="member-control direct-login">
          ${globalIdentifier.identifier} (${globalIdentifier.resource_server})
          <button class="header-btn logout">Logout</button>
        </div>
      `);
            this.replaceChild(memberControl, (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `div.member-control`));
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.logout`).addEventListener('click', () => {
                void this.logout();
            });
        }
        else if (!(0, lib_es2015_1.isLogin)(this.serverProps)) {
            const memberControl = (0, lib_es2015_2.buildNodeByOuterHTML)(`
        <div class="member-control logout">
          <button class="header-btn login">Login</button>
        </div>
      `);
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.login`).addEventListener('click', () => this.moveToLoginPage());
            this.replaceChild(memberControl, (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `div.member-control`));
        }
    }
}
exports.MainFrameHeader = MainFrameHeader;
const defineMainFrameHeader = (name) => (0, lib_es2015_3.defineIfUndefined)(name, MainFrameHeader);
exports.defineMainFrameHeader = defineMainFrameHeader;
