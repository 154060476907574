"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATTR_MAIN_ARTICLE_PK = exports.ATTR_IS_HIDE_EDIT_BUTTON = exports.ATTR_IS_LOGIN = exports.ATTR_QUERY = exports.ATTR_PAGE_SIZE = exports.ATTR_PAGE_NO = exports.ATTR_DATA_PK = exports.ATTR_IS_LIST_SHOW_CONTENT = exports.ATTR_ARTICLE_SUB_TYPE_NAME = exports.ATTR_ARTICLE_MAIN_TYPE_NAME = exports.ATTR_ROUTER_SUB_PATH_GROUP = exports.ATTR_ROUTER_PATH_GROUP = void 0;
exports.ATTR_ROUTER_PATH_GROUP = 'router-path-group';
exports.ATTR_ROUTER_SUB_PATH_GROUP = 'router-sub-path-group';
exports.ATTR_ARTICLE_MAIN_TYPE_NAME = 'article-main-type-name';
exports.ATTR_ARTICLE_SUB_TYPE_NAME = 'article-sub-type-name';
exports.ATTR_IS_LIST_SHOW_CONTENT = 'is-show-content';
exports.ATTR_DATA_PK = 'data-pk';
exports.ATTR_PAGE_NO = 'page_no';
exports.ATTR_PAGE_SIZE = 'page_size';
exports.ATTR_QUERY = 'query';
exports.ATTR_IS_LOGIN = 'is-login';
exports.ATTR_IS_HIDE_EDIT_BUTTON = 'is-hide-edit-button';
exports.ATTR_MAIN_ARTICLE_PK = 'main-article-pk';
