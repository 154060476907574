"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineHomePage = exports.HomePage = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
class HomePage extends HTMLElement {
    constructor() {
        super();
        this.innerHTML = `<div class="title">Document Server</div>`;
    }
}
exports.HomePage = HomePage;
const defineHomePage = (name) => (0, lib_es2015_1.defineIfUndefined)(name, HomePage);
exports.defineHomePage = defineHomePage;
