"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainArticleList = exports.MainArticleList = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-tables/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_4 = require("@hodol/document-editor/lib-es2015");
const constants_1 = require("./constants");
const common_1 = require("./common");
class MainArticleList extends HTMLElement {
    constructor() {
        super();
        this.fetchData = () => __awaiter(this, void 0, void 0, function* () {
            const request = {
                page_no: this.pageNo,
                page_size: this.pageSize,
                query: this.query,
            };
            const entityListPage = yield this.apis.listMainArticles(request);
            if (entityListPage === null) {
                this.table.setData([]);
                return;
            }
            console.log(`setup data in table`);
            this.table.setData(entityListPage.items);
            const pageNavigator = (0, lib_es2015_3.buildNodeByOuterHTML)(`<page-navigator id="page-navigator" page-no="${this.pageNo}" last-page-no="${entityListPage.last_page_no}"></page-navigator>`);
            pageNavigator.addEventListener('click-navigation-btn', (e) => {
                const targetPage = e.detail;
                const url = new URL(window.location.href);
                url.searchParams.set(constants_1.ATTR_PAGE_NO, `${targetPage}`);
                url.searchParams.set(constants_1.ATTR_PAGE_SIZE, `${this.pageSize}`);
                this.router.push(url.toString());
            });
            const currentPageNavigator = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'page-navigator');
            (0, lib_es2015_3.getParentElementOrThrowError)(currentPageNavigator).replaceChild(pageNavigator, currentPageNavigator);
        });
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.router = (0, lib_es2015_1.useRouter)();
            this.pageNo = Number.parseInt((0, lib_es2015_3.getAttributeOrDefault)(this, constants_1.ATTR_PAGE_NO, '1'));
            this.pageSize = Number.parseInt((0, lib_es2015_3.getAttributeOrDefault)(this, constants_1.ATTR_PAGE_SIZE, '10'));
            this.query = (0, lib_es2015_3.getAttributeOrDefault)(this, constants_1.ATTR_QUERY, '');
            this.isContentShow = (0, lib_es2015_3.getAttributeOrDefault)(this, constants_1.ATTR_IS_LIST_SHOW_CONTENT, 'false') === 'true';
            this.routerPathGroup = (0, lib_es2015_3.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_PATH_GROUP);
            this.documentViewer = new lib_es2015_4.DocumentViewer();
            this.innerHTML = `
      <div class="table-with-pageable">
        <div class="table-container">
          <div class="table-header">
            <input class="query">
            <button class="search">Search</button>
            <button class="reset">Reset</button>
            <button class="new">New</button>
          </div>
          <div class="list-container">
            <dynamic-table primary-key-name="pk" fixed-field-names="pk" field-names="title,created_by,created_at,updated_at" is-show-checkbox="true"></dynamic-table>
          </div>
        </div>
        <div>
          <page-navigator page-no="1" last-page-no="10"></page-navigator>
        </div>
      </div>
    `;
            this.table = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, `dynamic-table`);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.table.createVirtualCell = (content, primaryKey, fieldName, rowIndex, columnIndex) => {
                const entity = content;
                if (fieldName === 'title') {
                    const cell = (0, lib_es2015_3.buildNodeByOuterHTML)(`<div></div>`);
                    cell.appendChild((0, common_1.buildPresentableTitleOrEmptyPresentableTitle)(entity.title, this.documentViewer));
                    if (this.isContentShow) {
                        cell.appendChild((0, common_1.buildPresentableContentOrEmptyPresentableContent)(entity.content, this.documentViewer));
                    }
                    cell.addEventListener('click', () => {
                        this.router.push(`/web/${this.routerPathGroup}/view/${entity.pk}`);
                    });
                    return cell;
                }
                else {
                    const cell = (0, lib_es2015_3.buildNodeByOuterHTML)(`<div>${entity[fieldName]}</div>`);
                    cell.addEventListener('click', () => {
                        this.router.push(`/web/${this.routerPathGroup}/view/${entity.pk}`);
                    });
                    return cell;
                }
            };
            const searchBar = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, `input.query`);
            searchBar.addEventListener('change', () => {
                this.query = searchBar.value;
            });
            searchBar.addEventListener('keydown', e => {
                if (e.key === 'Enter') {
                    this.pageNo = 1;
                    void (() => __awaiter(this, void 0, void 0, function* () {
                        yield this.fetchData();
                    }))();
                }
            });
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.search').addEventListener('click', () => {
                this.pageNo = 1;
                void (() => __awaiter(this, void 0, void 0, function* () {
                    yield this.fetchData();
                }))();
            });
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.reset').addEventListener('click', () => {
                this.query = '';
                void (() => __awaiter(this, void 0, void 0, function* () {
                    yield this.fetchData();
                }))();
            });
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.new').addEventListener('click', () => {
                this.router.push(`/web/${this.routerPathGroup}/edit/0`);
            });
            yield this.fetchData();
        });
    }
}
exports.MainArticleList = MainArticleList;
const defineMainArticleList = (name) => {
    (0, lib_es2015_2.defineDynamicTable)('dynamic-table');
    (0, lib_es2015_2.definePageNavigator)('page-navigator');
    (0, lib_es2015_1.defineIfUndefined)(name, MainArticleList);
};
exports.defineMainArticleList = defineMainArticleList;
